import { Injectable } from '@angular/core';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { Link } from '@pepconnect/models/general/link.model';
import { ResponseMessage, UnserializedResponseMessage } from '@pepconnect/models/general/response-message.model';
import { LinksService } from '@pepconnect/services/links.service';
import { of, take } from 'rxjs';
import { TeamMember } from '../models/team-member.model';
import { TeamMemberHelpersService } from './team-member-helpers.service';
import { TeamReportsService } from './team-reports.service';
import { TeamsModalsService } from './teams-modals.service';

@Injectable({
  providedIn: 'root'
})
export class TeamMemberActionsService {

  constructor(private linksService: LinksService, private teamReportService: TeamReportsService, private teamsModalService: TeamsModalsService) { }
  membersHelpers = new TeamMemberHelpersService();

  throwForIncorrectArguments(member: TeamMember, teamMember: TeamMember) {
    if(this.membersHelpers.guardIsSelfTeamMember(member) || !this.membersHelpers.guardIsSelfTeamMember(teamMember)) {
      throw new Error("Incorrect team member or member passed to TeamMemberActionsService");
    }
  }

  /** Filter out ownerAssignPlan, ownerViewTranscript */
  filterNonPEPconnectionsLinks(ownerActions: Link[], teamMember: TeamMember) {
    if(!ownerActions) return ownerActions;
    return ownerActions?.filter(action => teamMember.team.hasPEPConnections ||
      (action.rel != LinkRel.ownerAssignPlan && action.rel != LinkRel.ownerViewTranscript && action.rel != LinkRel.ownerMemberPlanReport));
  }

  /** Creates a list of member actions - List of Member and Owner actions are combined after filtering out links not meant to be used inline */
  createActionList(member: TeamMember, teamMember: TeamMember) {
    this.throwForIncorrectArguments(member, teamMember);
    let ownerActions = teamMember.isAdmin ? member?.ownerActions ?? [] : [];
    ownerActions = this.filterNonPEPconnectionsLinks(ownerActions, teamMember);
    let memberActions = (!teamMember.isAdmin && teamMember.id == member.id) ? member.memberActions : [];
    let actions = [...ownerActions, ...memberActions];
    return actions;
  }

  createPlanMemberActionList(member: TeamMember, teamMember: TeamMember) {
    this.throwForIncorrectArguments(member, teamMember);
    let ownerActions = teamMember.isAdmin ? member?.selectedPlan?.ownerActions ?? [] : [];
    ownerActions = this.filterNonPEPconnectionsLinks(ownerActions, teamMember);
    let memberActions = (!teamMember.isAdmin && teamMember.id == member.id) ? member.selectedPlan.memberActions : [];
    let actions = [...ownerActions, ...memberActions];
    return actions;
  }

  /** Dispatches the action based on the rel.  Returns an observable that can be checked for a response if a post action happened. */
  dispatch(action: Link, member: TeamMember, teamMember: TeamMember, silentActions: boolean) {
    this.throwForIncorrectArguments(member, teamMember);
    switch (action.rel) {
      case LinkRel.page:
        this.linksService.gotoPage(action);
        break;
      case LinkRel.ownerAssignPlan:
      case LinkRel.ownerViewTranscript:
        this.linksService.gotoLink(action);
        break;
      case LinkRel.ownerMemberPlanReport:
        this.teamReportService.printMemberPlan(teamMember.team.teamID, member.id).pipe(take(1)).subscribe();
        break;
      case LinkRel.ownerMemberQualPlanReport:
        // deprecated
        break;
      case LinkRel.ownerChangePlanDate:
        return this.teamsModalService.definitions.openChangeDueDateModal(member, member.selectedPlan).pipe(take(1));
      default:
        // this is a xhr action so we have to return a observable to check for success and take appropriate action.
        // If silentActions is false, toastr messages will shown
        return this.linksService.postAction<ResponseMessage | UnserializedResponseMessage>(action, silentActions).pipe(take(1));
    }
    return of();
  }

}
