<div class="row" [id]="'owner-plan-item-' + item.id">
    <div class="col-2 display-flex align-items-center">
        <app-item-status [item]="item" [showStatusDate]="false"></app-item-status>
    </div>
    <div class="col-10 display-flex align-items-center">
        <h4 class="font-size-xsmall margin-top-0 margin-bottom-0">{{ item.longName }}</h4>
    </div>
</div>
<div class="display-flex justify-content-center expander clickable">
    <span class="icon-dots-ellipsis-horizontal" [attr.aria-label]="label | translate" [attr.aria-expanded]="collapsed" (click)="toggleExpandCollapse()"></span>
</div>

<div *ngIf="!collapsed" class="font-size-small">
<p class="margin-top-2" [innerHTML]="item.description"> </p>
<p>
    <strong class="font-size-small">{{'LABEL_ACTIVITY_TYPE' | translate}}&nbsp;</strong> {{item.type.name  | translate}}
</p>
<p *ngIf="item.hours > 0">
    <strong class="font-size-small">{{'LABEL_ESTIMATED_TIME' | translate}}&nbsp;</strong>{{item.hours}}&nbsp;{{'HOURS' | translate}}
</p>
</div>
