
<div class="stacked-progress margin-top-qtr margin-bottom-qtr display-flex" *ngIf="!isStatusEmpty">
    <div *ngFor="let point of bars" class="stacked-progress-bar justify-content-center display-flex align-items-center" [ngStyle]="{ width: point.value + '%', 'background-color': point.fill, 'color': point.textColor }">
        <uimc-tooltip [content]="point.label | translate">
            <strong class="font-size-xsmall display-inline-block">{{ point.count }}</strong>
        </uimc-tooltip>
    </div>
  </div>
  <div class="stacked-progress margin-top-qtr margin-bottom-qtr display-flex bg-color-black-10 align-items-center" *ngIf="isStatusEmpty">
    <div class="stacked-progress-bar width-100 text-center font-size-xsmall">
        <strong class="display-inline-block text-adjust">{{ 'LEARNING_STATUS_NOTSTARTED' | translate }}</strong>
    </div>
</div>
