import { Injectable } from '@angular/core';
import { TeamFile, TeamFileType, TeamLibrary } from '@pepconnect/features/teams/models/team-library.model';
import { ResponseMessage, UnserializedResponseMessage } from '@pepconnect/models/general/response-message.model';
import { ApiService } from '@pepconnect/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class TeamFileService {

  constructor(private api: ApiService) { }

  getLimits(teamId: string) {
    return this.api.get<TeamLibrary>(`teams/${teamId}/files/limits`);
  }

  getFileTypes(teamId: string) {
    return this.api.get<TeamFileType[]>(`teams/${teamId}/files/types`);
  }

  update(teamId: string, teamFileGUID: string, teamFile: TeamFile) {
    return this.api.post<ResponseMessage>(`teams/${teamId}/files/${teamFileGUID}`, teamFile);
  }

  uploadFile(teamId: string, friendlyUrl: string, teamFileType: string, teamFile: File) {
    return this.api.postFile<string>(`teams/${teamId}/files/${friendlyUrl}/upload/${teamFileType}`, teamFile);
  }

  check(teamId: string, teamFile: TeamFile) {
    return this.api.post<UnserializedResponseMessage>(`teams/${teamId}/files/check`, teamFile);
  }

  delete(teamId: string, friendlyUrl: string) {
    return this.api.post<ResponseMessage>(`teams/${teamId}/files/${friendlyUrl}/delete`, {});
  }

  fetchTeamFiles(teamId: string) {
    return this.api.get<TeamLibrary>(`teams/${teamId}/files`);
  }

  fetchTeamFile(teamId: string, friendlyUrl: string) {
    return this.api.get<TeamFile>(`teams/${teamId}/files/${friendlyUrl}`);
  }

  recordTeamFileLaunch(teamId: string, friendlyUrl: string) {
    return this.api.post<ResponseMessage>(`teams/${teamId}/files/${friendlyUrl}/launch`, {});
  }
}
