<app-collection-item *ngIf="loading">
    <app-spinner></app-spinner>
</app-collection-item>
<div class="my-learning-plan-container justify-content-between member-plan-mini-card" (click)="goToPlan()" *ngIf="!loading">
    <div class="main">
        <div class="display-flex flex-column justify-content-end">
            <h4 class="font-size-small margin-top-0">{{ userLearningPlan.plan.name }}</h4>
            <small class="due-date text-muted" *ngIf="userLearningPlan.dueDate">{{ 'LEARNING_STATUS_DUE' | translate}} {{ userLearningPlan.dueDate | localeDate: 'longDate' }}</small>
        </div>
        <app-plans-pie-chart [status]="userLearningPlan.status"></app-plans-pie-chart>
    </div>
</div>
