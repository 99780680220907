import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { V1LearningObject } from '@pepconnect/models/content/learning-object-v1.model';
import { Link } from '@pepconnect/models/general/link.model';
import { LinksService } from '@pepconnect/services/links.service';
import { BasePlanCardComponent } from '@pepconnect/shared/plans-shared/components/base/base-plan-card/base-plan-card.component';
import * as arrayHelpers from '@pepconnect/utils/array-helpers';
import { constants } from '@pepconnect/constants/constants';

@Component({
  selector: 'app-member-plan-assignment',
  templateUrl: './member-plan-assignment.component.html',
  styleUrls: ['./member-plan-assignment.component.scss']
})
export class MemberPlanAssignmentComponent extends BasePlanCardComponent implements OnInit {

  @Input() teamMember: TeamMember;
  @Output() dueDateChanged = new EventEmitter();
  @Output() planRemoved = new EventEmitter();

  planItems: V1LearningObject[] = [];
  planItemsCache: V1LearningObject[] = [];
  showChangeDueDate = false;

  showLoadMore = false;
  showAllItems = false;

  constructor(private linksService: LinksService) {
    super();
  }

  ngOnInit() {
    if (this.hasSubItems) {
      this.planItemsCache = this.sortPlanItems([...this.userLearningPlan.plan.items]);
      this.planItems = arrayHelpers.take(this.planItemsCache, constants.DEFAULT_MEMBER_PLAN_ITEM_SIZE);
      if (this.planItemsCache.length > constants.DEFAULT_MEMBER_PLAN_ITEM_SIZE) {
        this.showLoadMore = true;
      }
    }
  }

  /** method that accepts an Array of PlanItems and sorts them by isComplete isInProgress and its index */
  sortPlanItems(planItems: V1LearningObject[]) {
    return planItems.sort((a, b) => {
      return this.getSortRank(a) - this.getSortRank(b);
    });
  }

  getSortRank(item: V1LearningObject) {
    if (item.isComplete) {
      return 2;
    } else if (item.isInProgress) {
      return 1;
    }
    return 0;
  }

  dispatch(action: Link, el: HTMLElement) {
    (el as any)._onClose();
    switch (action.rel) {
      case 'ownerChangePlanDate':
        this.toggleShowChangeDueDate();
        break;
      case 'ownerRemovePlan':
        this.linksService.postAction(action, true).pipe(take(1)).subscribe(() => this.planRemoved.emit());
        break;
    }
  }

  toggleLoadAll() {
    this.showAllItems = !this.showAllItems;
    if (this.showAllItems) {
      this.planItems = this.planItemsCache;
    } else {
      this.planItems = arrayHelpers.take(this.planItemsCache, constants.DEFAULT_MEMBER_PLAN_ITEM_SIZE);
    }
  }

  get loadAllLabel() {
    return this.showAllItems ? 'LINK_SHOWLESS' : 'LINK_VIEWALL';
  }

  toggleShowChangeDueDate() {
    this.showChangeDueDate = !this.showChangeDueDate;
  }

  planChanged() {
    this.toggleShowChangeDueDate();
    this.dueDateChanged.emit();
  }

}
