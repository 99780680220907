import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserLearningPlan } from '@pepconnect/models/plan/user-learning-plan.model';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { selectUserLocale } from '@pepconnect/state/auth/auth.selector';
import { AppState } from '@pepconnect/state/index';
import { takeUntil } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-member-plan-assignment-mini',
  templateUrl: './member-plan-assignment-mini.component.html',
  styleUrls: ['./member-plan-assignment-mini.component.scss']
})
export class MemberPlanAssignmentMiniComponent extends BaseSmartComponent implements OnInit {
  @Input() userLearningPlan: UserLearningPlan;
  loading: boolean = false;

  $locale = this.store.select((selectUserLocale));
  locale: string;

  constructor(protected store: Store<AppState>, private router: Router, private location: Location) {
    super(store);
  }

  ngOnInit(): void {
    this.setLocale();
  }

  setLocale() {
    this.$locale.pipe(takeUntil(this.ngUnsubscribe)).subscribe(l => {
      this.locale = l.locale;
    })
  }

  goToPlan(): void {
    this.loading = true;
    setTimeout(() => this.router.navigate([this.locale, 'groups', 'plan', this.userLearningPlan.teamMemberLearningPlanID]), 500);
    this.location.go(`${this.location.path(false)}#member-my-learning-plans`);
  }
}
