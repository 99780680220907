import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamMemberActionsService } from '@pepconnect/features/teams/services/team-member-actions.service';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { V1LearningObject } from '@pepconnect/models/content/learning-object-v1.model';
import { Link } from '@pepconnect/models/general/link.model';
import { BasePlanCardComponent } from '@pepconnect/shared/plans-shared/components/base/base-plan-card/base-plan-card.component';
import { AppState } from '@pepconnect/state/index';
import { take } from 'rxjs';

@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss']
})
export class PlanCardComponent extends BasePlanCardComponent implements OnInit  {

  @Input() item: V1LearningObject;
  @Input() teamMember: TeamMember;
  @Input() showAdditionalDetails: boolean;
  @Input() collectionItemWrap: boolean = true;

  action: Link;
  hideAccess = false;

  constructor(
    private teamMemberActionService: TeamMemberActionsService,
    protected store: Store<AppState>,
    private teamStore: TeamStore,
    private teamService: TeamsService,
  ) {
    super();
  }

  ngOnInit() {
    if(!this.teamMember) {
      this.teamStore.$teamMember.pipe(take(1)).subscribe(member => this.teamMember = member);
    }
  }

  dispatch(action) {
    this.teamMemberActionService.dispatch(action, this.teamMember, this.teamMember, true).subscribe((res) => {
      if (res) {
        this.teamService.fetchMember(this.teamMember.team.teamID, this.teamMember.id).pipe(take(1)).subscribe(member => this.teamStore.setSelectedTeamMember(member));
      }
    });
  }

}
