/** needs to be expanded - helps when you need to evaluate what kind of page you're looking at vs checking the route path */
export enum PageFamily {
  None,
  Team = 'group',
  Plan = 'Plan'
}

export enum PageSubFamily {
  None,
  Matot = 'customertraining',
}
