import { Component, Input } from '@angular/core';
import { UserLearningPlan } from '@pepconnect/models/plan/user-learning-plan.model';

@Component({ template: '' })
export class BasePlanCardComponent {

  @Input() userLearningPlan: UserLearningPlan;

  collapsed = true;

  get expandIcon() {
    return this.collapsed ? 'icon-arrow-down-small-light' : 'icon-arrow-up-small-light';
  }

  get label() { return this.collapsed ? 'ARIA_EXPAND' : 'ARIA_COLLAPSE'; }

  toggleExpandCollapse() {
    this.collapsed = !this.collapsed;
    return this.collapsed;
  }

  get hasSubItems() { return this.userLearningPlan?.plan?.items?.length  !== 0 }

}

