import { Component, Input, ChangeDetectionStrategy  } from '@angular/core';
import { V1LearningObject } from '@pepconnect/models/content/learning-object-v1.model';

@Component({
  selector: 'app-member-assignment-item',
  templateUrl: './member-assignment-item.component.html',
  styleUrls: ['./member-assignment-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberAssignmentItemComponent {

  collapsed = true;

  @Input() item: V1LearningObject;

  get label() { return this.collapsed ? 'ARIA_EXPAND' : 'ARIA_COLLAPSE'; }

  toggleExpandCollapse() {
    this.collapsed = !this.collapsed;
    return this.collapsed;
  }

}
