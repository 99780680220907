<ng-container *ngIf="!collectionItemWrap">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
<ng-container *ngIf="collectionItemWrap">
    <app-collection-item [fullBordered]="true" [compact]="true">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-collection-item>
</ng-container>

<ng-template #content>
    <app-item-status [item]="item" [showStatusDate]="false"></app-item-status>
    <h4 class="margin-top-0 font-size-large">{{item.longName}}</h4>
    <div >
        <div *ngIf="item.type.id !== 'competency'">
            <div class="font-size-xsmall">
                <span class="font-weight-bold color-black-60">{{ 'LABEL_ACTIVITY_TYPE' | translate}}</span>&nbsp;{{ item.type.name  | translate}}
            </div>
            <!-- for OLTs -->
            <div *ngIf="item.hours > 0" class="font-size-xsmall">
                <span class="font-weight-bold color-black-60">
                    {{ 'LABEL_ESTIMATED_TIME' | translate}}&nbsp;</span><app-total-time [isPlanPage]="true" [totalTime]="item.hours"></app-total-time>
            </div>
            <!-- for videos -->
            <div *ngIf="item.duration" class="font-size-xsmall">
                <span class="font-weight-bold color-black-60">
                    {{'LABEL_ESTIMATED_TIME' | translate}}&nbsp;</span><app-total-time [totalTime]="item.duration"></app-total-time>
            </div>
            <!-- for PDFs -->
            <div *ngIf="item.estimatedMinutes" class="font-size-xsmall">
                <span class="font-weight-bold color-black-60">
                    {{'LABEL_ESTIMATED_TIME' | translate}}&nbsp;</span><app-total-time [isPdf]="true" [totalTime]="item.estimatedMinutes"></app-total-time>
            </div>
        </div>
        <div *ngIf="!collapsed">
            <p [innerHTML]="item.description"></p>
            <div *ngIf="showAdditionalDetails" class="margin-top-half font-size-xsmall">
                <div>
                    <span class="font-weight-bold color-black-60">{{'LABEL_OBJECT_COUNTRY_ACCESS' | translate}}</span>&nbsp; {{item.countryAccess | translate}}
                </div>
                <div>
                    <span class="font-weight-bold color-black-60">{{'LABEL_OBJECT_ACCESS' | translate}}</span>&nbsp; {{item.groupAccess | translate}}
                </div>
            </div>
        </div>
        <div *ngIf="item.type.id === 'competency'">
                    <div *ngFor="let item of item.subitems">
                        <div>
                            <app-item-status [item]="item" [showStatusDate]="false"></app-item-status>
                            <button ng-click="buttonAction(item, member)" class="btn btn-xs plan-button {{item.mainAction.cssClass}} {{item.mainAction.rel}}">
                                {{item.mainAction.text | translate}}
                            </button>
                            <div>
                                <h5>{{item.longName}}</h5>
                            </div>

                        </div>
                    </div>
            </div>
    </div>
    <div class="display-flex justify-content-center activity-button">
        <span [ngClass]="expandIcon" *ngIf="item.description" class="margin-top-half"
              [attr.aria-label]="label | translate" [attr.aria-expanded]="collapsed" (click)="toggleExpandCollapse()">
        </span>
    </div>
</ng-template>
