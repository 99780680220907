import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewEncapsulation, Input, Output, NgModule } from '@angular/core';
import { AgCharts } from 'ag-charts-community';

// noinspection AngularIncorrectTemplateDefinition
class AgChartsAngular {
  constructor(elementDef, ngZone) {
    this.ngZone = ngZone;
    this._initialised = false;
    this.options = {};
    this.onChartReady = new EventEmitter();
    this._nativeElement = elementDef.nativeElement;
  }
  ngAfterViewInit() {
    const options = this.patchChartOptions(this.options);
    this.chart = this.runOutsideAngular(() => AgCharts.create(options));
    this._initialised = true;
    this.chart.chart.waitForUpdate().then(() => {
      this.onChartReady.emit(this.chart);
    });
  }
  // noinspection JSUnusedGlobalSymbols,JSUnusedLocalSymbols
  ngOnChanges(changes) {
    this.runOutsideAngular(() => {
      if (!this._initialised || !this.chart) {
        return;
      }
      AgCharts.update(this.chart, this.patchChartOptions(this.options));
    });
  }
  ngOnDestroy() {
    if (this._initialised && this.chart) {
      this.chart.destroy();
      this.chart = undefined;
      this._initialised = false;
    }
  }
  patchChartOptions(propsOptions) {
    const patchListeners = listenerConfig => {
      const config = listenerConfig ?? {};
      for (const [listenerName, listener] of Object.entries(config)) {
        if (!listener || typeof listener !== 'function') continue;
        config[listenerName] = (...args) => {
          this.runInsideAngular(() => listener(...args));
        };
      }
    };
    patchListeners(propsOptions?.legend?.listeners);
    patchListeners(propsOptions?.listeners);
    propsOptions.series?.forEach(series => {
      patchListeners(series.listeners);
    });
    if (propsOptions.container) {
      return propsOptions;
    }
    return {
      ...propsOptions,
      container: this._nativeElement
    };
  }
  runOutsideAngular(callback) {
    return this.ngZone ? this.ngZone.runOutsideAngular(callback) : callback();
  }
  runInsideAngular(callback) {
    return this.ngZone ? this.ngZone.run(callback) : callback();
  }
}
AgChartsAngular.ɵfac = function AgChartsAngular_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AgChartsAngular)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
AgChartsAngular.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AgChartsAngular,
  selectors: [["ag-charts-angular"]],
  inputs: {
    options: "options"
  },
  outputs: {
    onChartReady: "onChartReady"
  },
  standalone: true,
  features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function AgChartsAngular_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AgChartsAngular, [{
    type: Component,
    args: [{
      selector: 'ag-charts-angular',
      standalone: true,
      template: '',
      encapsulation: ViewEncapsulation.None
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, {
    options: [{
      type: Input
    }],
    onChartReady: [{
      type: Output
    }]
  });
})();
class AgChartsAngularModule {}
AgChartsAngularModule.ɵfac = function AgChartsAngularModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AgChartsAngularModule)();
};
AgChartsAngularModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AgChartsAngularModule
});
AgChartsAngularModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [AgChartsAngular]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AgChartsAngularModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [AgChartsAngular],
      exports: [AgChartsAngular]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AgChartsAngular, AgChartsAngularModule };
