import { Component, Input } from '@angular/core';
import { CompletionStatus } from '@pepconnect/models/progress/completion-status.model';
import { DataPointType, LearningPlanHelpers, PlanDataPoint } from '@pepconnect/shared/plans-shared/services/plan-helpers.service';
import { intToPercentString } from '@pepconnect/utils/generic-utils';
import { AgChartOptions, AgPieSeriesOptions } from 'ag-charts-community';

//https://www.ag-grid.com/javascript-charts/pie-series/#api-reference

@Component({
  selector: 'app-plans-pie-chart',
  templateUrl: './plans-pie-chart.component.html'
})
export class PlansPieChartComponent {
  private _status: CompletionStatus;
  @Input() set status(status: CompletionStatus) {
    this._status = status;
    this.options = this.createOptions();
  }

  get status() {
    return this._status;
  }

  valueKey = 'value';
  labelKey = 'label';
  countKey = 'count';

  clickedLabel: string;
  clickedValue: string;

  options: AgChartOptions;

  constructor(private planHelpers: LearningPlanHelpers) { }

  get show(): boolean {
    return !this.planHelpers.charts.statusHelpers.isPlanStatusEmpty(this.status)
  }

  get innerPieLabel(): string {
    if (this.planHelpers.charts.statusHelpers.isPlanStatusEmpty(this.status)) {
      return DataPointType.NotStarted;
    }

    return this.clickedLabel || DataPointType.Completed;
  }

  private nodeClick = (params) => {
    this.clickedLabel = params.datum.label;
    this.clickedValue = intToPercentString(params.datum.value);
  }

  private get innerPieValue(): string {
    if (this.planHelpers.charts.statusHelpers.isPlanStatusEmpty(this.status)) {
      return undefined;
    }

    return this.clickedValue || intToPercentString(this.planHelpers.charts.getCompletedDataPoint(this.status)?.value);
  }

  private get chartData(): PlanDataPoint[] {
    if (this.planHelpers.charts.statusHelpers.isPlanStatusEmpty(this.status)) {
      return [];
    }

    return this.planHelpers.charts.getAllDataPoints(this.status, true);
  }

  private createOptions(): AgChartOptions {
    const innerLabel = this.innerPieValue;
    return {
      legend: {
        enabled: false
      },
      data: this.chartData,
      theme: {
        palette: {
          fills: this.planHelpers.charts.chartColorHelpers.fills,
          strokes: this.planHelpers.charts.chartColorHelpers.strokes
        }
      },
      series: [
        {
          type: 'pie',
          listeners: {
            nodeClick: this.nodeClick.bind(this)
          },
          innerLabels: [
            { text: innerLabel }
          ],
          angleKey: this.valueKey,
          calloutLabelKey: this.labelKey,
          innerRadiusRatio: 0.75,
          tooltip: {
            renderer: ({ datum, calloutLabelKey }) => {
              return {
                content: `${datum[calloutLabelKey]}: ${this.planHelpers.numFormatter.format(
                  datum[this.countKey]
                )}`,
              };
            }
          },
          calloutLabel: {
            enabled: false
          },
          highlightStyle: {
            item: {
              fill: undefined
            },
            series: {
              dimOpacity: 0.5
            }
          },
        } as AgPieSeriesOptions
      ]
    };
  }

}

