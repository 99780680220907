import { Component, Input, OnInit } from '@angular/core';
import { PlanWithCompletionStatus } from '@pepconnect/interfaces/icompletion-status.interface';
import { LearningPlan } from '@pepconnect/models/plan/learning-plan.model';
import { UserLearningPlan } from '@pepconnect/models/plan/user-learning-plan.model';
import { LearningPlanHelpers } from '@pepconnect/shared/plans-shared/services/plan-helpers.service';

@Component({
  selector: 'app-mini-learning-plan-status',
  templateUrl: './mini-learning-plan-status.component.html'
})
export class MiniLearningPlanStatusComponent implements OnInit {
  @Input() plan: PlanWithCompletionStatus;

  title: string;
  constructor(private planHelpers: LearningPlanHelpers) { }

  ngOnInit(): void {
    this.title = this.planHelpers.getPlanName(this.plan);
  }

}
