import { Injectable } from "@angular/core";
import { ApiMainService } from "@pepconnect/extensions/api-main.service";
import { PrintService } from "@pepconnect/services/print.service";
import { take, tap } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TeamReportsService {

  constructor(private api: ApiMainService, private printService: PrintService) { }

  printGroupMemberReport(teamId: string) {
    return this.api.get<string>(`teams/${teamId}/members/print`).pipe(take(1), tap(res => this.printService.printRequest(res)));
  }

  printGroupMemberActivityReport(teamId: string, teamLearningPlanId?: string) {
    const reportUrl = `teams/${teamId}/members/activity/print`;
    if (teamLearningPlanId) {
      return this.api.get<string>(`${reportUrl}?teamLearningPlanId=${teamLearningPlanId}`).pipe(take(1), tap(res => this.printService.printRequest(res)));
    }
    else {
      return this.api.get<string>(reportUrl).pipe(take(1), tap(res => this.printService.printRequest(res)));
    }
  }

  printGroupActivityReport(teamId: string) {
    return this.api.get<string>(`teams/${teamId}/activity/print`).pipe(take(1), tap(res => this.printService.printRequest(res)));
  }

  printCredits(teamId: string, isPdf: boolean) {
    return this.api.get<string>(`teams/${teamId}/credits/report/${(isPdf ? 'pdf' : 'excel')}`).pipe(take(1), tap(res => this.printService.printRequest(res)));
  }

  printMemberPlan(teamId: string, teamMemberId: string) {
    return this.api.get<string>(`teams/${teamId}/members/${teamMemberId}/plan/print`).pipe(take(1), tap(res => this.printService.printRequest(res)));
  }


}
