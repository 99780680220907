<app-collection [id]="'member-plan-' + userLearningPlan.teamMemberLearningPlanID" *ngIf="userLearningPlan" [compact]="true">
    <app-collection-item>
        <uimc-custom-dropdown class="menu-dropdown" right-aligned *ngIf="userLearningPlan && userLearningPlan.ownerActions" #dd>
            <div slot="content">
                <span class="icon-dots-ellipsis-vertical color-black-60" role="button"></span>
            </div>
            <uimc-menu>
                <uimc-menu-item *ngFor="let action of userLearningPlan.ownerActions" (click)="dispatch(action, dd)">{{ action.text | translate }}</uimc-menu-item>
            </uimc-menu>
        </uimc-custom-dropdown>
        <div class="display-flex justify-content-between plan-card">
            <div class="display-flex flex-column justify-content-center padding-left-1">
                <h4 class="font-size-medium margin-bottom-0 margin-top-0">{{ userLearningPlan.plan.name }}</h4>
                <strong class="font-size-xxsmall color-black-60">{{'TEXT_ASSIGNEDON'| translate}} {{ userLearningPlan.assignDate | localeDate: 'longDate' }}</strong>
                <strong class="font-size-xxsmall color-black-60" *ngIf="userLearningPlan.dueDate">{{ 'LEARNING_STATUS_DUE' | translate}} {{ userLearningPlan.dueDate | localeDate: 'longDate' }}</strong>
            </div>
            <app-plans-pie-chart [status]="userLearningPlan.status"></app-plans-pie-chart>
        </div>
        <div class="display-flex justify-content-center" *ngIf="showChangeDueDate">
            <app-change-due-date [teamMember]="teamMember" [userLearningPlan]="userLearningPlan" (cancelled)="toggleShowChangeDueDate()" (changed)="planChanged()" ></app-change-due-date>
        </div>
        <div class="display-flex justify-content-center">
            <span [ngClass]="expandIcon" [class.visibility-hidden]="!hasSubItems" role="button"
                [attr.aria-label]="label | translate" [attr.aria-expanded]="collapsed" (click)="toggleExpandCollapse()">
            </span>
            <div *ngIf="!hasSubItems" class="font-size-xsmall">{{ 'TITLE_PLANNOITEMS' | translate }}</div>
        </div>
    </app-collection-item>
    <ng-container *ngIf="!collapsed">
        <app-collection-item *ngFor="let item of planItems">
            <app-member-assignment-item [item]="item"></app-member-assignment-item>
        </app-collection-item>
        <app-collection-item *ngIf="showLoadMore" (click)="toggleLoadAll()" class="clickable-collection-item">
            <span class="font-size-xsmall">
                <span class="icon-arrow-right margin-right-half color-orange"></span> <strong>{{ loadAllLabel | translate }}</strong>
            </span>
        </app-collection-item>
    </ng-container>
</app-collection>
