import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlansPieChartComponent } from './components/charts/plans-pie-chart/plans-pie-chart.component';
import { PlansBarChartComponent } from './components/charts/plans-bar-chart/plans-bar-chart.component';
import { SharedModule } from '@pepconnect/shared/module';
import { MemberPlanAssignmentComponent } from './components/team/cards/member-plan-assignment/member-plan-assignment.component';
import { PlanCardComponent } from './components/self/cards/plan-card/plan-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { AgChartsAngularModule  } from 'ag-charts-angular';
import { MemberAssignmentItemComponent } from './components/team/cards/member-plan-assignment/member-assignment-item/member-assignment-item.component';
import { ChangeDueDateComponent } from './components/team/change-due-date/change-due-date.component';
import { MiniLearningPlanStatusComponent } from './components/team/cards/mini-learning-plan-status/mini-learning-plan-status.component';
import { MemberPlanAssignmentMiniComponent } from './components/team/cards/member-plan-assignment-mini/member-plan-assignment-mini.component';
import { InlinePlanStatusComponent } from './components/status/inline-plan-status/inline-plan-status.component';

@NgModule({
  declarations: [
    PlansPieChartComponent,
    PlansBarChartComponent,
    MemberPlanAssignmentComponent,
    PlanCardComponent,
    MemberAssignmentItemComponent,
    ChangeDueDateComponent,
    MiniLearningPlanStatusComponent,
    MemberPlanAssignmentMiniComponent,
    InlinePlanStatusComponent,
  ],
  imports: [
    CommonModule,
    AgChartsAngularModule ,
    SharedModule,
    TranslateModule.forChild()
  ],
  exports: [
    PlansPieChartComponent,
    PlansBarChartComponent,
    MemberPlanAssignmentComponent,
    PlanCardComponent,
    ChangeDueDateComponent,
    MiniLearningPlanStatusComponent,
    MemberPlanAssignmentMiniComponent,
    InlinePlanStatusComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class PlansSharedModule { }
