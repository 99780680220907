import { Component, Input } from '@angular/core';
import { CompletionStatus } from '@pepconnect/models/progress/completion-status.model';

@Component({
  selector: 'app-inline-plan-status',
  templateUrl: './inline-plan-status.component.html',
  styleUrls: ['./inline-plan-status.component.scss'],
})
export class InlinePlanStatusComponent {
@Input() status: CompletionStatus;
}
