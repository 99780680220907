<div *ngIf="status" class="inline-plan-status">
    <div class="plan-status font-size-xsmall">
        <span class="margin-right-half">
            <h5 class="margin-zero font-size-xsmall">{{'PLAN_MENU_LINK1' | translate}}:</h5>
        </span>
        <div class="font-size-xsmall color-black-60">
            <strong>
                {{"LEARNING_STATUS_NOTSTARTED" | translate}}
            </strong>:&nbsp;{{status?.notStarted}}
        </div>
        <div class="font-size-xsmall color-black-60">
            <strong>
                {{"LEARNING_STATUS_INCOMPLETE" | translate}}
            </strong>:&nbsp;{{status?.inProgress}}
        </div>
        <div class="font-size-xsmall color-black-60">
            <strong>
                {{"LEARNING_STATUS_COMPLETED" | translate}}
            </strong>:&nbsp;{{status?.completed}}
        </div>
    </div>
</div>
