import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CompletionStatus } from '@pepconnect/models/progress/completion-status.model';
import { LearningPlanHelpers, PlanDataPoint } from '@pepconnect/shared/plans-shared/services/plan-helpers.service';

/** Displays the progress of a LearningPlan or UserLearning plan as a stacked bar chart */
@Component({
  selector: 'app-plans-bar-chart',
  templateUrl: './plans-bar-chart.component.html',
  styleUrls: ['./plans-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlansBarChartComponent implements OnInit {
  @Input() status: CompletionStatus;

  isStatusEmpty: boolean;
  dataPoints: PlanDataPoint[];
  bars: PlanDataPoint[];
  totalValue: number;
  constructor(private planHelpers: LearningPlanHelpers) { }

  ngOnInit(): void {
    this.isStatusEmpty = this.planHelpers.charts.statusHelpers.isPlanStatusEmpty(this.status);
    this.dataPoints = this.planHelpers.charts.getAllDataPoints(this.status, true);
    const pointsWithData = this.dataPoints.filter(x => x.value > 0);
    // calc new total value from points with more than 0
    const total = pointsWithData.reduce((acc, curr) => acc + curr.count, 0);
    this.bars = pointsWithData.map(p => {
      return {
        ...p,
        value: +Math.round(p.count * 100 / total) // calc new percentage based on synthetic total
      };
    });

  }


}
